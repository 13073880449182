import React, { useEffect } from "react";
import "./PreNavbar.css";
import Logo from "../../../Assets/Logo/sathish_logo.webp";
import { Link, useLocation } from "react-router-dom";
import { Headset, Mail } from "tabler-icons-react";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Burger, Drawer } from "@mantine/core";

const PreNavbar = () => {
  const prenavbar787MediaQuery = useMediaQuery("(max-width:787px");
  const [opened, { open, close }] = useDisclosure();
  const location = useLocation();
  useEffect(() => {
    if (!prenavbar787MediaQuery) {
      close();
    }
  }, [prenavbar787MediaQuery, close]);
  return (
    <div>
      <div
        className={
          prenavbar787MediaQuery ? "prenavbar-mobile-div" : "prenavbar-div"
        }
      >
        <div className="prenavbar-div-left">
          <div className="prenavbar-div-left-img">
            <Link className="navbar-link" to="/">
              <img src={Logo} alt="website-logo" />
            </Link>
          </div>
        </div>
        <div className="prenavbar-div-right">
          {prenavbar787MediaQuery ? (
            opened ? (
              ""
            ) : (
              <Burger
                size="sm"
                opened={opened}
                onClick={open}
                aria-label="Toggle navigation"
              />
            )
          ) : (
            <>
              <div className="prenavbar-div-right-individual">
                <div className="prenavbar-div-right-individual-left">
                  <Mail
                    size={prenavbar787MediaQuery ? 36 : 46}
                    strokeWidth={1.5}
                    color={"#42464e"}
                  />
                </div>
                <div className="prenavbar-div-right-individual-right">
                  <ul>
                    <li>Email us</li>
                    <li>
                      <h3>sech.krr@gmail.com</h3>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prenavbar-div-right-individual">
                <div className="prenavbar-div-right-individual-left">
                  <Headset
                    size={prenavbar787MediaQuery ? 36 : 46}
                    strokeWidth={1.5}
                    color={"#42464e"}
                  />
                </div>
                <div className="prenavbar-div-right-individual-right">
                  <ul>
                    <li>Call us now</li>
                    <li>
                      <h3>8807655155</h3>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prenavbar-div-right-individual-btn">
                <a className="appointment-btn" href="tel:+91 8807655155">
                  APPOINTMENT
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      <Drawer
        style={{ Background: "#8e2a80" }}
        opened={opened}
        size="xs"
        onClose={close}
        className="prenavbar-drawer"
      >
        <ul>
          <Link
            className={
              location.pathname === "/" ? "navbar-active" : "navbar-link"
            }
            to="/"
            onClick={close}
          >
            <li>Home</li>
          </Link>
          <Link
            className={
              location.pathname === "/about" ? "navbar-active" : "navbar-link"
            }
            to="/about"
            onClick={close}
          >
            <li>About Us</li>
          </Link>
          <Link
            className={
              location.pathname === "/services"
                ? "navbar-active"
                : "navbar-link"
            }
            to="/services"
            onClick={close}
          >
            <li>Services</li>
          </Link>
          <Link
            className={
              location.pathname === "/gallery" ? "navbar-active" : "navbar-link"
            }
            to="/gallery"
            onClick={close}
          >
            <li>Gallery</li>
          </Link>
          <Link
            className={
              location.pathname === "/contact" ? "navbar-active" : "navbar-link"
            }
            to="/contact"
            onClick={close}
          >
            <li>Contact Us</li>
          </Link>
        </ul>
      </Drawer>
    </div>
  );
};

export default PreNavbar;
