import React from "react";
import "./HomeSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation, Autoplay } from "swiper/modules";
import home_slider_icon1 from "../../Assets/HomeSlider/Icons/home_slider_icon1.webp";
import home_slider_icon2 from "../../Assets/HomeSlider/Icons/home_slider_icon2.webp";
import home_slider_icon3 from "../../Assets/HomeSlider/Icons/home_slider_icon3.webp";
import home_slider_icon4 from "../../Assets/HomeSlider/Icons/home_slider_icon4.webp";
import { Title } from "@mantine/core";
const HomeSlider = () => {
  return (
    <div>
      <div className="homeslider-div">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          navigation={true}
          modules={[Navigation, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="slider-div1">
              <div className="slider-div-image">
                <div className="slider-div-image-content">
                  <div className="slider-div2-image-heading">
                    <p>VISION CARE FOR LIFE</p>
                  </div>
                  <div className="slider-div2-image-sub-heading">
                    <Title order={1}>
                      <span className="slider-div2-image-sub-heading-fontstyle">
                        Welcome to{" "}
                      </span>
                      <br />
                      <strong>Sathish Eye Care</strong>
                    </Title>
                  </div>
                  <div className="slider-div2-image-para">
                    <p>Family Focused, Lifetime Care.</p>
                  </div>
                  <div className="slider-div2-image-icons">
                    <div className="slider-div2-image-icons-individual">
                      <img src={home_slider_icon1} alt="home_slider_icon" />
                    </div>
                    <div className="slider-div2-image-icons-individual">
                      <img src={home_slider_icon2} alt="home_slider_icon" />
                    </div>
                    <div className="slider-div2-image-icons-individual">
                      <img src={home_slider_icon3} alt="home_slider_icon" />
                    </div>
                    <div className="slider-div2-image-icons-individual">
                      <img src={home_slider_icon4} alt="home_slider_icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-div2">
              <div className="slider-div2-image">
                <div className="slider-div2-image-content">
                  <div className="slider-div-image-heading">
                    <p>EYE CARE , WE CARE</p>
                  </div>
                  <div className="slider-div-image-sub-heading">
                    <Title order={1}>
                      <span className="slider-div-image-sub-heading-fontstyle">
                        <span className="color-slider">The Difference</span>{" "}
                        <br />
                        is{" "}
                      </span>{" "}
                      clear to see
                    </Title>
                  </div>
                  <div className="slider-div2-image-para">
                    <p>Love your eyes, love your future</p>
                  </div>
                  <div className="slider-div2-image-icons">
                    <div className="slider-div2-image-icons-individual">
                      <img src={home_slider_icon1} alt="home_slider_icon" />
                    </div>
                    <div className="slider-div2-image-icons-individual">
                      <img src={home_slider_icon2} alt="home_slider_icon" />
                    </div>
                    <div className="slider-div2-image-icons-individual">
                      <img src={home_slider_icon3} alt="home_slider_icon" />
                    </div>
                    <div className="slider-div2-image-icons-individual">
                      <img src={home_slider_icon4} alt="home_slider_icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default HomeSlider;
