import React, { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import {
  BrandFacebook,
  BrandGoogle,
  BrandInstagram,
  ClockHour9,
  MapPin,
} from "tabler-icons-react";
import "./Header.css";

const Header = () => {
  const header787MediaQuery = useMediaQuery("(max-width:787px)");

  const [isHoverFacebook, setIsHoverFacebook] = useState(false);

  const handleMouseEnterFacebook = () => {
    setIsHoverFacebook(true);
  };

  const handleMouseLeaveFacebook = () => {
    setIsHoverFacebook(false);
  };
  const [isHoverInsta, setIsHoverInsta] = useState(false);

  const handleMouseEnterInsta = () => {
    setIsHoverInsta(true);
  };

  const handleMouseLeaveInsta = () => {
    setIsHoverInsta(false);
  };
  const [isHoverGoogle, setIsHoverGoogle] = useState(false);

  const handleMouseEnterGoogle = () => {
    setIsHoverGoogle(true);
  };

  const handleMouseLeaveGoogle = () => {
    setIsHoverGoogle(false);
  };

  return (
    <div>
      <div className="header-div">
        <div className="header-div-container-left">
          {/* <ul>
            <li className='clock-icon-header'>
              <ClockHour9
                size={22}
                strokeWidth={1.5}
                color={'#ffffff'}
              />
            </li>
            <li>Open Hours: Mon - Sat 09:30 - 20:00</li>
          </ul> */}
          <ul>
            <li className="clock-icon-header">
              <MapPin
                size={header787MediaQuery ? 14 : 18}
                strokeWidth={header787MediaQuery ? 0.8 : 1.5}
                color={"#de2679"}
              />
            </li>
            <li>
              No 2 Muthu nagar,Near Thinnappa Theatre Karur,Pin code
              639002,Tamilnadu, TN.
            </li>
          </ul>
        </div>
        <div className="header-div-container-right">
          {/* <ul>
            <li className='clock-icon-header'>
              <Mail
                size={22}
                strokeWidth={1.5}
                color={'#ffffff'}
              />
            </li>
            <li className='header-link'><a href="mailto:sech.krr@gmail.com">sech.krr@gmail.com</a></li>
          </ul>
          <div className="vertical-line"></div>
          <ul>
            <li className='clock-icon-header'>
              <Phone
                size={22}
                strokeWidth={1.5}
                color={'#ffffff'}
              />
            </li>
            <li className='header-link'><a href="tel:+91 8807655155">8807655155</a></li>
          </ul>
          <div className="vertical-line"></div>
          <ul>
            <li className='clock-icon-header' onMouseEnter={handleMouseEnterFacebook} onMouseLeave={handleMouseLeaveFacebook}>
              <BrandFacebook
                size={22}
                strokeWidth={1.5}
                color={isHoverFacebook ? '#de2679' : '#ffffff'}
                className='icon-hover'
              />
            </li>
            <li className='clock-icon-header' onMouseEnter={handleMouseEnterInsta} onMouseLeave={handleMouseLeaveInsta}>
              <BrandInstagram
                size={22}
                strokeWidth={1.5}
                color={isHoverInsta ? '#de2679' : '#ffffff'}
                className='icon-hover'
              />
            </li>
            <li className='clock-icon-header' onMouseEnter={handleMouseEnterGoogle} onMouseLeave={handleMouseLeaveGoogle}>
              <BrandGoogle
                size={22}
                strokeWidth={2}
                color={isHoverGoogle ? '#de2679' : '#ffffff'}
                className='icon-hover'
              />
            </li>
          </ul> */}

          <ul>
            <li className="clock-icon-header">
              <ClockHour9
                size={header787MediaQuery ? 14 : 18}
                strokeWidth={header787MediaQuery ? 1.2 : 1.5}
                color={"#de2679"}
              />
            </li>
            <li>Open Hours: Mon - Sat 09:30 - 20:00</li>
          </ul>
          <ul>
            <li
              className="clock-icon-header"
              onMouseEnter={handleMouseEnterFacebook}
              onMouseLeave={handleMouseLeaveFacebook}
            >
              <BrandFacebook
                size={header787MediaQuery ? 16 : 18}
                strokeWidth={1.5}
                color={isHoverFacebook ? "#de2679" : "#42464e"}
                className="icon-hover"
              />
            </li>
            <li
              className="clock-icon-header"
              onMouseEnter={handleMouseEnterInsta}
              onMouseLeave={handleMouseLeaveInsta}
            >
              <BrandInstagram
                size={header787MediaQuery ? 16 : 18}
                strokeWidth={1.5}
                color={isHoverInsta ? "#de2679" : "#42464e"}
                className="icon-hover"
              />
            </li>
            <li
              className="clock-icon-header"
              onMouseEnter={handleMouseEnterGoogle}
              onMouseLeave={handleMouseLeaveGoogle}
            >
              <BrandGoogle
                size={header787MediaQuery ? 14 : 16}
                strokeWidth={2}
                color={isHoverGoogle ? "#de2679" : "#42464e"}
                className="icon-hover"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
