import React, { useEffect, useRef, useState } from "react";
import HomeSlider from "../../Components/HomeSlider/HomeSlider";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "./Home.css";
import Sathish from "../../Assets/Sathish1.webp";
import { Check, Quote, X } from "tabler-icons-react";
import home_ourservices1 from "../../Assets/Home/home_ourservices1.webp";
import home_ourservices2 from "../../Assets/Home/home_ourservices2.webp";
import home_ourservices3 from "../../Assets/Home/home_ourservices3.webp";
import home_ourservices4 from "../../Assets/Home/home_ourservices4.webp";
import rightarrow from "../../Assets/Home/rightarrow.webp";
import leftarrow from "../../Assets/Home/leftarrow.webp";
import user from "../../Assets/Home/user.webp";
import config from "../../config/server/server";
import {
  Button,
  Card,
  Input,
  Rating,
  Textarea,
  ThemeIcon,
} from "@mantine/core";

//
import emailjs from "@emailjs/browser";
import { showNotification } from "@mantine/notifications";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { readReview } from "../../config/queries/Review";

const Home = () => {
  const [appoinment, setAppointment] = useState({
    patient_name: "",
    patient_email: "",
    patient_message: "",
  });

  const form = useRef();

  const handleSendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    const userName = formData.get("user_name");
    const userEmail = formData.get("user_email");
    const userMessage = formData.get("user_message");
    if (userName === "") {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "red" }}>Name Field is Required</p>,
      });
    } else if (userEmail === "") {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "red" }}>Email Field is Required</p>,
      });
    } else if (!userEmail.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "red" }}>Please Enter Valid Email</p>,
      });
    } else if (userMessage === "") {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "red" }}>Message Field is Required</p>,
      });
    } else {
      emailjs
        .sendForm(
          "service_272668c",
          "template_j6tiem9",
          form.current,
          "FwSOvLz3MGe_Iwn-n"
        )
        .then(
          (result) => {
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                  <Check color="green" />
                </ThemeIcon>
              ),
              message: (
                <p style={{ color: "green" }}>
                  Appointment Mailed Successfully
                </p>
              ),
            });
            setAppointment({
              patient_name: "",
              patient_email: "",
              patient_message: "",
            });
          },
          (error) => {
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                  <X color="red" />
                </ThemeIcon>
              ),
              message: <p style={{ color: "red" }}>{error.text}</p>,
            });
          }
        );
    }
  };

  // const [reviewTable, setReviewTable] = useState([]);

  // const databaseUrl =
  //   "https://sathish-eyecare-default-rtdb.asia-southeast1.firebasedatabase.app";

  // const fetchReviews = async () => {
  //   try {
  //     const response = await fetch(`${databaseUrl}/review.json`);

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch reviews");
  //     }

  //     const data = await response.json();
  //     let values = Object.values(data);
  //     const key = Object.keys(data);
  //     let id = [];
  //     for (var i = 0; i < values?.length; i++) {
  //       id.push({ id: key[i] });
  //       // values[i] = [values[i], id[i]];
  //       values[i] = { ...values[i], ...id[i] };
  //     }

  //     setReviewTable(values || []);
  //   } catch (error) {
  //     console.error("Error fetching reviews:", error.message);
  //   }
  // };

  // useEffect(() => {
  //   fetchReviews();
  // }, []);

  //Api fetching

  // useQuery("allreviews", readReview, {
  //   onSuccess: (result) => {
  //     console.log(result);
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });
  const [reviewTable, setReview] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.baseApi}review/allreviews.php`);
        const data = await response.json(); // Parse the JSON data
        setReview(data); // Set the parsed data to the state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // const [review, setReview] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         "http://localhost/sathish_eyecare_admin/controller/review/allreviews.php"
  //       );

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }

  //       const data = await response.json();
  //       console.log("Response data:", data); // Log the data

  //       setReview(data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // console.log(review);

  return (
    <div>
      <HomeSlider />
      <div className="home-div">
        <div className="home-div-container">
          <div className="home-div-container-left">
            <div className="home-div-container-left-content">
              <h4 className="heading-1">Ophthal</h4>
              <h2 className="heading-2">
                Your Eyes, <br />
                Our Expertise
              </h2>
              <p className="content-1">
                “Dr.T.M. Sathish Kumaar is dedicated to providing his patients
                with the best possible care. We at EyeCare are focused on
                helping you.”
              </p>
              <div className="content-2-doctor-name">
                <h6 className="content-2-doctor-name-heading-1">
                  Dr.T.M. Sathish Kumaar MBBS.,M.S (Ophthal)
                </h6>
                <p className="content-2-doctor-name-heading-2">
                  Glaucoma Consultant. Former Consultant - Aravind Eye Hospital,
                  Madurai
                </p>
              </div>
            </div>
            <div className="home-div-container-left-image">
              <img src={Sathish} alt="Director" />
              <div className="home-div-container-left-image-quote">
                <Quote size={60} strokeWidth={1} color={"white"} />
              </div>
            </div>
          </div>
          <div className="home-div-container-right">
            <div className="home-div-container-right-content">
              <div className="home-div-container-right-content-heading">
                <h2>Our Working Hours</h2>
                <p>
                  Sathish Eye Care & Hospital is a super speciality Eye Care
                  Hospital in karur
                </p>
              </div>
              <div className="home-div-container-right-content-working-hours">
                <div className="home-div-container-right-content-working-hours-day">
                  <ul>
                    <li>Monday</li>
                    <li>Tuesday</li>
                    <li>Wednesday</li>
                    <li>Thursday</li>
                    <li>Friday</li>
                    <li>Saturday</li>
                    <li>Sunday</li>
                  </ul>
                </div>
                <div className="home-div-container-right-content-working-hours-time">
                  <ul>
                    <li>09:30 - 20:00</li>
                    <li>09:30 - 20:00</li>
                    <li>09:30 - 20:00</li>
                    <li>09:30 - 20:00</li>
                    <li>09:30 - 20:00</li>
                    <li>09:30 - 20:00</li>
                    <li>09:30 - 13:00</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-services">
          <div className="our-services-divcontainer">
            <div className="our-services-divcontainer-heading">
              <div className="our-services-divcontainer-heading-left">
                <h4>OUR VISION</h4>
                <h1>
                  GIVE BEST CARE FOR YOUR EYES WITH <br />
                  <strong className="our-services-divcontainer-heading-span">
                    OUR EYE SERVICES
                  </strong>
                </h1>
              </div>
              <div className="our-services-divcontainer-heading-right">
                <p>
                  Sathish eye care's state-of-the art facilities , latest
                  treatment techniques, highly experienced and well trained eye
                  specialist , competent staff and certified technicians allow
                  us to provide superior eye care for patients and attain the
                  best possible results.
                </p>
              </div>
            </div>
            <div className="our-services-divcontainer-content">
              <div className="our-services-divcontainer-content-individual">
                <div className="our-services-divcontainer-content-individual-image">
                  <img src={home_ourservices1} alt="home_ourservices1" />
                </div>
                <div className="our-services-divcontainer-content-individual-heading">
                  <h2>Comprehensive Eye Check up</h2>
                </div>
                <div className="our-services-divcontainer-content-individual-content">
                  {/* <p>
                    Our Comprehensive Ophthalmology services provide patients
                    with Primary Eye Care.
                  </p> */}
                  <p>
                    Our Comprehensive Ophthalmology services provide patients
                    with Primary Eye Care.
                  </p>
                </div>
                <div className="our-services-divcontainer-content-individual-readmore">
                  <Button
                    className="readmore-btn"
                    component={Link}
                    to="/services"
                  >
                    {"Read More >>"}
                  </Button>
                </div>
              </div>
              <div className="our-services-divcontainer-content-individual">
                <div className="our-services-divcontainer-content-individual-image">
                  <img src={home_ourservices2} alt="home_ourservices1" />
                </div>
                <div className="our-services-divcontainer-content-individual-heading">
                  <h2>Glaucoma Services</h2>
                </div>
                <div className="our-services-divcontainer-content-individual-content">
                  {/* <p>
                    Glaucoma treatments include Medicines, Laser
                    Trabeculoplasty, Laser Peripheral Iridotomy, Conventional
                    Surgery, or a Combination of any of these While these
                    treatments.
                  </p> */}
                  <p>
                    Glaucoma treatments include medicines, laser
                    trabeculoplasty,conventional surgery, or a Combination of
                    any of these.
                  </p>
                </div>
                <div className="our-services-divcontainer-content-individual-readmore">
                  <Button
                    className="readmore-btn"
                    component={Link}
                    to="/services"
                  >
                    {"Read More >>"}
                  </Button>
                </div>
              </div>
              <div className="our-services-divcontainer-content-individual">
                <div className="our-services-divcontainer-content-individual-image">
                  <img src={home_ourservices3} alt="home_ourservices1" />
                </div>
                <div className="our-services-divcontainer-content-individual-heading">
                  <h2>Cataract Services</h2>
                </div>
                <div className="our-services-divcontainer-content-individual-content">
                  <p>
                    He is wellversed with all kinds of cataract surgeries (more
                    than 50000 surgeries) Phacoemulsification and SICS.
                  </p>
                </div>
                <div className="our-services-divcontainer-content-individual-readmore">
                  <Button
                    className="readmore-btn"
                    component={Link}
                    to="/services"
                  >
                    {"Read More >>"}
                  </Button>
                </div>
              </div>
              <div className="our-services-divcontainer-content-individual">
                <div className="our-services-divcontainer-content-individual-image">
                  <img src={home_ourservices4} alt="home_ourservices1" />
                </div>
                <div className="our-services-divcontainer-content-individual-heading">
                  <h2>Retina Services</h2>
                </div>
                <div className="our-services-divcontainer-content-individual-content">
                  {/* <p>
                    We actively look for the presence of retinal problems in all
                    our patients by dilated fundas examinations.
                  </p> */}
                  <p>
                    We actively look for the presence of retinal problems in all
                    our patients by dilated fundas examinations.
                  </p>
                </div>
                <div className="our-services-divcontainer-content-individual-readmore">
                  <Button
                    className="readmore-btn"
                    component={Link}
                    to="/services"
                  >
                    {"Read More >>"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-our-counters">
          <div className="home-our-counters-div">
            <h1>10+</h1>
            <p>Years of experiences</p>
          </div>
          <div className="home-our-counters-div">
            <h1>10+</h1>
            <p>Experience Staff's</p>
          </div>
          <div className="home-our-counters-div">
            <h1>5000+</h1>
            <p>Happy Patients</p>
          </div>
        </div>
        <div className="home-testimonials-appoinmentform">
          <div className="home-testimonials-appoinmentform-testimonials">
            <div className="home-testimonials-appoinmentform-testimonials-heading">
              <h4>TESTIMONIALS</h4>
              <h1>
                Read what{" "}
                <strong className="home-testimonials-appoinmentform-testimonials-heading-span">
                  Our Happy Patients{" "}
                </strong>
                Says
              </h1>
            </div>
            <div className="home-testimonials-appoinmentform-testimonials-rating-quote">
              <div className="home-testimonials-appoinmentform-testimonials-rating-quote-rating">
                <Rating value={5} readOnly size="lg" />
              </div>
              <Swiper
                navigation={{
                  nextEl: ".swiper-button-next-btn",
                  prevEl: ".swiper-button-previous-btn",
                  disabled: false,
                }}
                modules={[Navigation]}
                className="review-slider"
              >
                {reviewTable.length === 0
                  ? ""
                  : reviewTable.map((reviewtable, index) => {
                      return (
                        <SwiperSlide key={index} className="review-slide">
                          <div className="home-testimonials-appoinmentform-testimonials-rating-quote-review">
                            <div className="home-testimonials-appoinmentform-testimonials-rating-quote-review-content">
                              <p>{reviewtable.review}</p>
                            </div>
                            <div className="home-testimonials-appoinmentform-testimonials-rating-quote-review-name">
                              <div className="home-testimonials-appoinmentform-testimonials-rating-quote-review-name-img">
                                <img src={user} alt="user icon" />
                              </div>
                              <p>{reviewtable.reviewer}</p>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}

                <SwiperSlide className="review-slide">
                  <div className="home-testimonials-appoinmentform-testimonials-rating-quote-review">
                    <div className="home-testimonials-appoinmentform-testimonials-rating-quote-review-content">
                      <p>
                        I recently had a check-up at Sathish EyeCare, and my
                        experience was largely positive. The staff was welcoming
                        and efficient, making my visit smooth and stress-free.
                        The doctor was knowledgeable and thorough, explaining
                        everything clearly.
                      </p>
                    </div>
                    <div className="home-testimonials-appoinmentform-testimonials-rating-quote-review-name">
                      <div className="home-testimonials-appoinmentform-testimonials-rating-quote-review-name-img">
                        <img src={user} alt="user icon" />
                      </div>
                      <p>Surya Prakash</p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="review-slider-prev-nxt-btn">
                <button className="swiper-button-previous-btn">
                  <img src={leftarrow} alt="leftarrow icon" width="14" />
                  Previous
                </button>
                <button className="swiper-button-next-btn">
                  Next
                  <img src={rightarrow} alt="rightarrow icon" width="14" />
                </button>
              </div>
            </div>
          </div>
          <div className="home-testimonials-appoinmentform-form">
            <Card className="home-testimonials-appoinmentform-form-card">
              <div className="home-testimonials-appoinmentform-form-card-heading">
                <h2>Get a Appointment</h2>
              </div>
              <form
                ref={form}
                className="home-testimonials-appoinmentform-form-card-content"
              >
                <div className="home-testimonials-appoinmentform-form-card-content-form-individual">
                  <Input.Wrapper>
                    <Input
                      name="user_name"
                      placeholder="Your Name"
                      onChange={(e) =>
                        setAppointment({
                          ...appoinment,
                          patient_name: e.target.value,
                        })
                      }
                      value={appoinment.patient_name}
                    />
                  </Input.Wrapper>
                </div>
                <div className="home-testimonials-appoinmentform-form-card-content-form-individual">
                  <Input.Wrapper>
                    <Input
                      name="user_email"
                      placeholder="Your Email Address"
                      onChange={(e) =>
                        setAppointment({
                          ...appoinment,
                          patient_email: e.target.value,
                        })
                      }
                      value={appoinment.patient_email}
                    />
                  </Input.Wrapper>
                </div>
                <div className="home-testimonials-appoinmentform-form-card-content-form-individual">
                  <Input.Wrapper>
                    <Textarea
                      name="user_message"
                      placeholder="Message..."
                      minRows={4}
                      autosize
                      onChange={(e) =>
                        setAppointment({
                          ...appoinment,
                          patient_message: e.target.value,
                        })
                      }
                      value={appoinment.patient_message}
                    />
                  </Input.Wrapper>
                </div>
                <div className="home-testimonials-appoinmentform-form-card-content-form-btn">
                  <button onClick={(e) => handleSendEmail(e)}>
                    Make Appointment
                  </button>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
