//import react packages
import React, { useState, useEffect } from "react";
import { ArrowBarToUp } from "tabler-icons-react";

//import BottomToTopButton css file
import "./BottomToTopButton.css";

//import uparrow image from assets

function BottomToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    setIsVisible(scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <ArrowBarToUp
      size={30}
      strokeWidth={2}
      color={"white"}
      className={`bottom-to-top-button ${isVisible ? "visible" : ""}`}
      onClick={scrollToTop}
    />
  );
}

export default BottomToTopButton;
