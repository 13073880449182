import React from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
const Navbar = () => {
  const location = useLocation();
  return (
    <div>
      <div className="navbar-div">
        <div className="navbar-div-right">
          <ul>
            <Link
              className={
                location.pathname === "/" ? "navbar-active" : "navbar-link"
              }
              to="/"
            >
              <li>Home</li>
            </Link>
            <Link
              className={
                location.pathname === "/about" ? "navbar-active" : "navbar-link"
              }
              to="/about"
            >
              <li>About Us</li>
            </Link>
            <Link
              className={
                location.pathname === "/services"
                  ? "navbar-active"
                  : "navbar-link"
              }
              to="/services"
            >
              <li>Services</li>
            </Link>
            <Link
              className={
                location.pathname === "/gallery"
                  ? "navbar-active"
                  : "navbar-link"
              }
              to="/gallery"
            >
              <li>Gallery</li>
            </Link>
            <Link
              className={
                location.pathname === "/contact"
                  ? "navbar-active"
                  : "navbar-link"
              }
              to="/contact"
            >
              <li>Contact Us</li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
