import React, { useRef, useState } from "react";
import { Input, Textarea, ThemeIcon } from "@mantine/core";
import "./Contact.css";

import emailjs from "@emailjs/browser";
import { showNotification } from "@mantine/notifications";
import { Check, X } from "tabler-icons-react";

const Contact = () => {
  const [contactDetails, setContactDetails] = useState({
    contactname: "",
    contactemail: "",
    contactmobile: "",
    contactmessage: "",
  });

  const form = useRef();

  const handleSendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    const userName = formData.get("user_name");
    const userEmail = formData.get("user_email");
    const userMobile = formData.get("user_mobile");
    const userMessage = formData.get("user_message");
    if (userName === "") {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "red" }}>Name Field is Required</p>,
      });
    } else if (userEmail === "") {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "red" }}>Email Field is Required</p>,
      });
    } else if (!userEmail.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "red" }}>Please Enter Valid Email</p>,
      });
    } else if (userMobile === "") {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: (
          <p style={{ color: "red" }}>Mobile Number Field is Required</p>
        ),
      });
    } else if (!userMobile.trim().match(/^[0-9]{10}$/)) {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "red" }}>Enter Vaild Mobile Number</p>,
      });
    } else if (userMessage === "") {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "red" }}>Message Field is Required</p>,
      });
    } else {
      emailjs
        .sendForm(
          "service_272668c",
          "template_0bo6b5u",
          form.current,
          "FwSOvLz3MGe_Iwn-n"
        )
        .then(
          (result) => {
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                  <Check color="green" />
                </ThemeIcon>
              ),
              message: (
                <p style={{ color: "green" }}>
                  Contact Details Mailed Successfully
                </p>
              ),
            });
            setContactDetails({
              contactname: "",
              contactemail: "",
              contactmobile: "",
              contactmessage: "",
            });
          },
          (error) => {
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                  <X color="red" />
                </ThemeIcon>
              ),
              message: <p style={{ color: "red" }}>{error.text}</p>,
            });
          }
        );
    }
  };

  return (
    <div>
      <div className="contactus-div">
        <div className="contactus-div-map">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                title="Map of Sathish EyeCare, Karur"
                className="gmap_iframe"
                width="100%"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=600&amp;height=500&amp;hl=en&amp;q=Sathish EyeCare, No 2 Muthu nagar,Near Thinnappa Theatre Karur,Pin code 639002,Tamilnadu, TN.&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
              <a href="https://connectionsgame.org/">Connections NYT</a>
            </div>
          </div>
        </div>
        <div className="contactus-div-form-opentimings">
          <div className="contactus-div-container-right-content">
            <div className="contactus-div-container-right-content-heading">
              <h2>Our Working Hours</h2>
              <p>
                Sathish Eye Care & Hospital is a super speciality Eye Care
                Hospital in karur
              </p>
            </div>
            <div className="contactus-div-container-right-content-working-hours">
              <div className="contactus-div-container-right-content-working-hours-day">
                <ul>
                  <li>Monday</li>
                  <li>Tuesday</li>
                  <li>Wednesday</li>
                  <li>Thursday</li>
                  <li>Friday</li>
                  <li>Saturday</li>
                  <li>Sunday</li>
                </ul>
              </div>
              <div className="contactus-div-container-right-content-working-hours-time">
                <ul>
                  <li>09:30 - 20:00</li>
                  <li>09:30 - 20:00</li>
                  <li>09:30 - 20:00</li>
                  <li>09:30 - 20:00</li>
                  <li>09:30 - 20:00</li>
                  <li>09:30 - 20:00</li>
                  <li>09:30 - 13:00</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="contactus-div-form">
            <div className="contactus-div-form-heading">
              <h2>Contact Us</h2>
            </div>
            <form ref={form}>
              <div className="contactus-div-form-individual">
                <Input.Wrapper>
                  <Input
                    name="user_name"
                    placeholder="Full Name"
                    value={contactDetails.contactname}
                    onChange={(e) => {
                      setContactDetails({
                        ...contactDetails,
                        contactname: e.target.value,
                      });
                    }}
                  />
                </Input.Wrapper>
              </div>
              <div className="contactus-div-form-individual">
                <Input.Wrapper>
                  <Input
                    name="user_email"
                    placeholder="Email Id"
                    value={contactDetails.contactemail}
                    onChange={(e) => {
                      setContactDetails({
                        ...contactDetails,
                        contactemail: e.target.value,
                      });
                    }}
                  />
                </Input.Wrapper>
              </div>
              <div className="contactus-div-form-individual">
                <Input.Wrapper>
                  <Input
                    name="user_mobile"
                    placeholder="Mobile Number"
                    value={contactDetails.contactmobile}
                    onChange={(e) => {
                      setContactDetails({
                        ...contactDetails,
                        contactmobile: e.target.value,
                      });
                    }}
                  />
                </Input.Wrapper>
              </div>
              <div className="contactus-div-form-individual">
                <Input.Wrapper>
                  <Textarea
                    name="user_message"
                    placeholder="Message.."
                    autosize
                    minRows={3}
                    value={contactDetails.contactmessage}
                    onChange={(e) => {
                      setContactDetails({
                        ...contactDetails,
                        contactmessage: e.target.value,
                      });
                    }}
                  />
                </Input.Wrapper>
              </div>
              <div className="contactus-div-form-btn">
                <button onClick={(e) => handleSendEmail(e)}>Submit Form</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
