import "./App.css";
import Routers from "./Routers";
import BottomToTopButton from "./Components/BottomToTopButton/BottomToTopButton";
import WhatsappButton from "./Components/WhatsappButton/WhatsappButton";

function App() {
  return (
    <div>
      <Routers />
      <BottomToTopButton />
      <WhatsappButton />
    </div>
  );
}

export default App;
