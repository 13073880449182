import React from "react";
import { Link } from "react-router-dom";
import "./About.css";
import sathish from "../../Assets/Sathish.webp";
import doctor from "../../Assets/About/doctor.webp";
import eye from "../../Assets/About/eye.webp";
import careful from "../../Assets/About/careful.webp";
const About = () => {
  return (
    <div>
      <div className="about-div">
        <div className="about-div-banner">
          <div className="about-banner-content">
            <h1>About Us</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>{" "}
              /<li>About Us</li>
            </ul>
          </div>
        </div>
        <div className="about-div-content">
          <div className="about-div-content-image">
            <img src={sathish} alt="sathish icon" />
            <div className="about-div-content-image-podium">
              <p>Dr.T.M. Sathish Kumaar MBBS.,M.S</p>
            </div>
          </div>
          <div className="about-div-content-content">
            <div className="our-services-divcontainer-heading-left">
              <h4>OPHTHALMOLOGIST</h4>
              <h1>
                Eye Care Services For <br />
                <strong className="our-services-divcontainer-heading-span">
                  Child and Adults
                </strong>
              </h1>
            </div>
            <p>
              Sathish Eye Care & Hospital is a super speciality Eye Care
              Hospital in karur,committed to providing quality eye care &
              treatment of eye disorders to all section of society.Sathish eye
              care's state-of-the art facilities , latest treatment techniques,
              highly experienced and well trained eye specialist , competent
              staff and certified technicians allow us to provide superior eye
              care for patients and attain the best possible results.
            </p>
            <p>
              Dr.T.M. Sathish Kumaar MBBS.,M.S Ophthal, Glaucoma Specialist.
              Done his MBBS in vinayaga mission university.Got university first
              mark in 1st and 2nd Year of MBBS. Got best paper award in Anatomy
              and best paper award in EMCON 2007 & 2008 both papers got
              published in the international journal of emergency medicine. Got
              gold medal in Anatomy, SPM and Ortho.
            </p>
            <p>
              Done his MS in PSG Medical College in Coimbatore. Got best paper
              Award in Glaucoma Session in TNOA 2015 Conducted at Chennai.
            </p>
            <p>
              Done his Glaucoma fellowship in Aravind Eye Hospital Madurai.
              Worked as a consultant in Aravind Eye Hospital, Theni. where he
              also got trained in Retinal lasers ( Pan retinal Photo
              Coagulation,Barrage lasers ) and Intra Vitreal injections. Worked
              in Aarthy Eye Hospital as a consultant for 4 years in Karur.
            </p>
          </div>
        </div>
        <div className="about-div-content-vision">
          <div className="about-div-content-vision-heading">
            <h4>WHY PEOPLE TRUST US</h4>
            <h1>
              WE PRESERVE, ENHANCE, <br />
              <strong className="our-services-divcontainer-heading-span">
                AND PROTECT YOUR VISION
              </strong>
            </h1>
          </div>
          <div className="about-div-content-vision-content">
            <div className="about-div-content-vision-content-individual">
              <div className="about-div-content-vision-content-individual-image">
                <img src={doctor} alt="doctor icon" />
              </div>
              <div className="about-div-content-vision-content-individual-heading">
                <h2>Leading Specialists</h2>
              </div>
              <div className="about-div-content-vision-content-individual-content">
                <p>
                  Our specialists are passionate about staying up-to-date with
                  the latest advancements in eye care. This commitment to
                  continuous learning means our patients receive the most
                  advanced and effective treatments available.
                </p>
              </div>
            </div>
            <div className="about-div-content-vision-content-individual">
              <div className="about-div-content-vision-content-individual-image">
                <img src={eye} alt="eye icon" />
              </div>
              <div className="about-div-content-vision-content-individual-heading">
                <h2>Best Treatment</h2>
              </div>
              <div className="about-div-content-vision-content-individual-content">
                <p>
                  Experience the best of eye care with our cutting-edge
                  treatments and professional specialists. Sathish Eye Care is
                  your best buddy when it comes to your vision.
                </p>
              </div>
            </div>
            <div className="about-div-content-vision-content-individual">
              <div className="about-div-content-vision-content-individual-image">
                <img src={careful} alt="careful icon" />
              </div>
              <div className="about-div-content-vision-content-individual-heading">
                <h2>Careful Surgery</h2>
              </div>
              <div className="about-div-content-vision-content-individual-content">
                <p>
                  We're dedicated to ensuring your eyes receive the gentlest,
                  most expert care available, because your vision is our focus
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
