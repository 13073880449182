import React, { useEffect, useState } from "react";
import "./Gallery.css";
import gallery1 from "../../Assets/Gallery/gallery1.webp";
import gallery2 from "../../Assets/Gallery/gallery2.webp";
import ImageModal from "../../Components/ImageModal/ImageModal";
import config from "../../config/server/server";
const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const imageArray = [
    {
      image: gallery1,
    },
    {
      image: gallery2,
    },
  ];

  const [galleryTable, setGallery] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${config.baseApi}gallery/allgalleryimages.php`
        );
        const data = await response.json(); // Parse the JSON data
        setGallery(data); // Set the parsed data to the state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  
  return (
    <div>
      <div className="gallery-div">
        <div className="gallery-div-heading">
          <h1>Gallery</h1>
        </div>
        <div className="gallery-div-content">
          {galleryTable.map((image, index) => {
            return (
              <div key={index} className="gallery-div-content-individual">
                <img
                  src={`${
                    config.imageApi
                  }assets/gallery/${image.gallery_image.replaceAll(" ", "%20")}`}
                  alt={`gallery-${index}`}
                  onClick={() => openImageModal(`${config.imageApi}assets/gallery/${image.gallery_image.replaceAll(" ", "%20")}`)}
                />
              </div>
            );
          })}
          {selectedImage && (
            <ImageModal imageUrl={selectedImage} closeModal={closeImageModal} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
