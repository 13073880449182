import React from "react";
import "./ImageModal.css";
const ImageModal = ({ imageUrl, closeModal }) => {
  return (
    <div className="image-modal" onClick={closeModal}>
      <div className="modal-content">
        <img src={imageUrl} alt="dynamic modal" />
      </div>
    </div>
  );
};

export default ImageModal;
