import React from "react";
// import { useMediaQuery } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Services from "./Pages/Services/Services";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Gallery from "./Pages/Gallery/Gallery";
import Header from "./Layouts/Header/Header";
import PreNavbar from "./Layouts/Navbar/PreNavbar/PreNavbar";
import Navbar from "./Layouts/Navbar/Navbar";
import Footer from "./Layouts/Footer/Footer";
import AddReview from "./Pages/AddReview/AddReview";

const Routers = () => {
  const location = useLocation();
  window.scrollTo(0, 0);
  const bodyContent = {
    "/": <Home />,
    "/services": <Services />,
    "/about": <About />,
    "/contact": <Contact />,
    "/gallery": <Gallery />,
    "/addreview": <AddReview />,
  };
  const renderBodyData = bodyContent[location.pathname] || null;
  return (
    <div>
      <div className="md-q787">
        <Header />
      </div>
      <div className="sticky-navbar">
        <PreNavbar />
        <div className="md-q787">
          <Navbar />
        </div>
      </div>
      <div>{renderBodyData}</div>
      <Footer />
    </div>
  );
};

export default Routers;
