import { Input, ThemeIcon } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { X } from "tabler-icons-react";

const LoginForm = ({ onLogin }) => {
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const loginUser = {
    email: "admin@sathisheyecare.com",
    password: "Sathish@2023",
  };

  const handleLogin = () => {
    if (
      login.email === loginUser.email &&
      login.password === loginUser.password
    ) {
      sessionStorage.setItem("userLogin", true);
      onLogin();
    } else {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "red" }}>Invalid Credentials</p>,
      });
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <div className="add-review-div-container-content-form">
        <div className="add-review-div-container-content-form-individual">
          <Input.Wrapper label="Email">
            <Input
              type="text"
              onChange={(e) => setLogin({ ...login, email: e.target.value })}
              value={login.email}
            />
          </Input.Wrapper>
        </div>
        <div className="add-review-div-container-content-form-individual">
          <Input.Wrapper label="Password">
            <Input
              type="password"
              onChange={(e) => setLogin({ ...login, password: e.target.value })}
              value={login.password}
            />
          </Input.Wrapper>
        </div>
        <div className="add-review-div-container-content-form-btn">
          <button onClick={handleLogin}>Login</button>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
