import React from "react";
import { BrandWhatsapp } from "tabler-icons-react";
import "./WhatsappButton.css";
const WhatsappButton = () => {
  return (
    <a
      href="https://wa.me/+918807655155"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button"
    >
      <BrandWhatsapp size={30} strokeWidth={2} color={"white"} />
    </a>
  );
};

export default WhatsappButton;
