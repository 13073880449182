const local = {
    baseApi:"http://localhost/sathish_eyecare_admin/controller/",
    imageApi:"http://localhost/sathish_eyecare_admin/"
}

const live = {
    baseApi:"https://sathisheyecare.com/admin/controller/",
    imageApi:"https://sathisheyecare.com/admin/"
}

const config = {
    local,
    live,
  };
  
  export default config["live"];