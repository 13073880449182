import React from "react";
import "./Footer.css";
import Logo from "../../Assets/Logo/sathish_logo.webp";
import {
  Mail,
  MapPin,
  Phone,
  BrandFacebook,
  BrandInstagram,
  BrandGoogle,
  BrandWhatsapp,
  Headset,
} from "tabler-icons-react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div>
      <div className="home-make-appoinment">
        <div className="home-make-appoinment-left">
          <div className="home-make-appoinment-left-icon">
            <Headset size={56} strokeWidth={1.5} color={"#42464e"} />
          </div>
          <div className="home-make-appoinment-left-heading">
            <h1>
              If you Have Any Questions Schedule an Appointment <br />
              <strong className="home-make-appoinment-left-heading-span">
                With Our Doctor OR Call Us On 8807655155{" "}
              </strong>
            </h1>
          </div>
        </div>
        <div className="home-make-appoinment-right">
          <a href="tel:+91 8807655155">
            <button>MAKE AN APPOINTMENT</button>
          </a>
        </div>
      </div>
      <div className="footer-div">
        <div className="footer-div-main-footer">
          <div className="footer-div-main-footer-ourdetails">
            <div className="footer-div-main-footer-ourdetails-logo">
              <img src={Logo} alt="website logo" />
            </div>
            <p>
              Sathish EyeCare committed to provide the best services in eye care
              through best improvements of systems, process, technology.
            </p>
            <div className="footer-div-newsletter-social-links">
              <div className="footer-div-newsletter-social-links-individual">
                <BrandFacebook
                  size={22}
                  strokeWidth={1.5}
                  color="#ffffff"
                  className="icon-hover"
                />
              </div>
              <div className="footer-div-newsletter-social-links-individual">
                <BrandInstagram
                  size={22}
                  strokeWidth={1.5}
                  color="#ffffff"
                  className="icon-hover"
                />
              </div>
              <div className="footer-div-newsletter-social-links-individual">
                <BrandGoogle
                  size={22}
                  strokeWidth={2}
                  color="#ffffff"
                  className="icon-hover"
                />
              </div>
              <div className="footer-div-newsletter-social-links-individual">
                <a
                  href="https://wa.me/+918807655155"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-hover"
                >
                  <BrandWhatsapp size={22} strokeWidth={1.5} color="#ffffff" />
                </a>
              </div>
            </div>
            {/* <ul>
              <li className="clock-icon-header">
                <Mail size={22} strokeWidth={1.5} color={"#ffffff"} />
              </li>
              <li className="header-link">
                <a href="mailto:sech.krr@gmail.com">sech.krr@gmail.com</a>
              </li>
            </ul>
            <ul>
              <li className="clock-icon-header">
                <Phone size={22} strokeWidth={1.5} color={"#ffffff"} />
              </li>
              <li className="header-link">
                <a href="tel:+91 8807655155">8807655155</a>
              </li>
            </ul>
            <ul>
              <li className="clock-icon-header">
                <MapPin size={22} strokeWidth={1.5} color={"#ffffff"} />
              </li>
              <li>
                No 2 Muthu nagar,Near Thinnappa Theatre Karur,Pin code
                639002,Tamilnadu, TN.
              </li>
            </ul> */}
          </div>
          <div className="footer-div-main-footer-departments">
            <div className="footer-div-main-footer-departments-heading">
              <h3>Usefull Links</h3>
              <div className="heading-underline"></div>
            </div>
            <div className="footer-div-main-footer-departments-content">
              <ul>
                <li>
                  <Link to="/contact" className="fotter-link">
                    {">"} Make Appointments
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="fotter-link">
                    {">"} Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="fotter-link">
                    {">"} Treatments
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="fotter-link">
                    {">"} About Us
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="fotter-link">
                    {">"} Services
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" className="fotter-link">
                    {">"} Gallery
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-div-main-footer-quicklinks">
            <div className="footer-div-main-footer-quicklinks-heading">
              <h3>Our Services</h3>
              <div className="heading-underline"></div>
            </div>
            <div className="footer-div-main-footer-quicklinks-content">
              <ul>
                <li>
                  <Link to="/services" className="fotter-link">
                    {">"} Comprehensive Eye Check up
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="fotter-link">
                    {">"} Glaucoma Services
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="fotter-link">
                    {">"} Cataract Services
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="fotter-link">
                    {">"} Retina Services
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-div-main-footer-openinghours">
            <div className="footer-div-main-footer-openinghours-heading">
              <h3>Get In Touch</h3>
              <div className="heading-underline"></div>
            </div>
            <div className="footer-div-main-footer-openinghours-content">
              <ul>
                <li className="clock-icon-header">
                  <Mail size={22} strokeWidth={1.5} color={"#de2679"} />
                </li>
                <li className="footer-link">
                  <a href="mailto:sech.krr@gmail.com">sech.krr@gmail.com</a>
                </li>
              </ul>
              <ul>
                <li className="clock-icon-header">
                  <Phone size={22} strokeWidth={1.5} color={"#de2679"} />
                </li>
                <li className="footer-link">
                  <a href="tel:+91 8807655155">8807655155</a>
                </li>
              </ul>
              <ul>
                <li className="clock-icon-header">
                  <MapPin size={22} strokeWidth={1.5} color={"#de2679"} />
                </li>
                <li>
                  No 2, Muthu Nagar,Near Thinnappa Theatre ,Karur,
                  ,Tamilnadu, IN<br />Pin code - 639002.
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="footer-div-newsletter">
          <div className="footer-div-newsletter-heading">
            <h4>Subscribe Our Newsletters & Stay Update</h4>
          </div>
          <div className="footer-div-newsletter-form">
            <Input.Wrapper label="" description="" error="">
              <Input
                value={newsLetter}
                placeholder="Enter Your Email"
                onChange={(e) => {
                  setNewsLetter(e.target.value);
                }}
              />
            </Input.Wrapper>
            <button>Sign Up!</button>
          </div>
          <div className="footer-div-newsletter-social-links">
            <div className="footer-div-newsletter-social-links-individual">
              <BrandFacebook
                size={22}
                strokeWidth={1.5}
                color="#ffffff"
                className="icon-hover"
              />
            </div>
            <div className="footer-div-newsletter-social-links-individual">
              <BrandInstagram
                size={22}
                strokeWidth={1.5}
                color="#ffffff"
                className="icon-hover"
              />
            </div>
            <div className="footer-div-newsletter-social-links-individual">
              <BrandGoogle
                size={22}
                strokeWidth={2}
                color="#ffffff"
                className="icon-hover"
              />
            </div>
            <div className="footer-div-newsletter-social-links-individual">
              <BrandWhatsapp
                size={22}
                strokeWidth={1.5}
                color="#ffffff"
                className="icon-hover"
              />
            </div>
          </div>
        </div> */}
        <hr />
        <div className="footer-div-copyright">
          <div className="footer-div-copyright-container">
            <p>
              Copyright &copy; 2023{" "}
              <Link to="/" className="footer-link">
                Sathish EyeCare.
              </Link>{" "}
              All rights reserved. Designed By{" "}
              <a className="footer-link" href="https://www.weboney.com">
                Weboney
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
