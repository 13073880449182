import React from "react";
import { Link } from "react-router-dom";
import "./Services.css";
import services1 from "../../Assets/Services/services1.webp";
import services2 from "../../Assets/Services/services2.webp";
import services3 from "../../Assets/Services/services3.webp";
import services4 from "../../Assets/Services/services4.webp";
const Services = () => {
  return (
    <div>
      <div className="services-div">
        <div className="services-div-banner">
          <div className="services-banner-content">
            <h1>Services</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>{" "}
              /<li>Services</li>
            </ul>
          </div>
        </div>
        <div className="services-div-content">
          <div className="services-div-content-checkup">
            <div className="services-div-content-checkup-left">
              <img src={services1} alt="services1" />
            </div>
            <div className="services-div-content-checkup-right">
              <div className="services-div-content-checkup-right-heading">
                <h2>Comprehensive Eye Check up</h2>
                {/* <div className="service-underline"></div> */}
              </div>
              <div className="services-div-content-checkup-right-content">
                <p>
                  Our comprehensive ophthalmology services provide patients with
                  primary eye care, including medical and surgical management
                  for an array of eye conditions, from general eye exams to
                  state-of-the-art diagnostic equipment to ophthalmic surgery.
                  We are equipped to take care of all eye care needs. Our
                  comprehensive eye services provide patients with primary eye
                  care, including medical and surgical management of a wide
                  range of eye diseases. We are able to handle all aspects of
                  eye care, from general eye exams to cutting-edge diagnostic
                  technology to ophthalmic surgery.
                </p>
              </div>
            </div>
          </div>
          <div className="services-div-content-glaucoma-services">
            <div className="services-div-content-glaucoma-services-left">
              <div className="services-div-content-glaucoma-services-left-heading">
                <h2>Glaucoma Services</h2>
              </div>
              <div className="services-div-content-glaucoma-services-left-content">
                <p>
                  Glaucoma treatments include medicines, laser
                  trabeculoplasty,conventional surgery, or a combination of any
                  of these. While these treatments may save remaining vision,
                  they do not improve sight already lost from glaucoma.
                  Medicines in the form of eye drops are the most common early
                  treatment for glaucoma. We realize the relevance of your
                  vision. That is why we provide high-quality glaucoma services,
                  which are designed to detect, regulate, and ultimately save
                  your vision. Glaucoma develops when the pressure inside the
                  eye reaches levels that damage these nerve fibers, leading
                  some of them to stop working. The loss of functioning nerve
                  fibers in the affected area of origin causes visual
                  abnormalities.
                </p>
              </div>
            </div>
            <div className="services-div-content-glaucoma-services-right">
              <img src={services2} alt="services2" />
            </div>
          </div>
          <div className="services-div-content-cataract-services">
            <div className="services-div-content-cataract-services-left">
              <img src={services3} alt="services3" />
            </div>
            <div className="services-div-content-cataract-services-right">
              <div className="services-div-content-cataract-services-right-heading">
                <h2>Cataract Services</h2>
              </div>
              <div className="services-div-content-cataract-services-right-content">
                <p>
                  He is well versed in all kinds of cataract surgeries (more
                  than 50,000 surgeries), phacoemulsification, and SICS. We give
                  safe eye cataract surgery based on the kind of cataract, which
                  includes cortical, intumescent, nuclear, posterior
                  subcapsular, rosette, and traumatic cataracts. We also offer
                  juvenile cataract treatment and can efficiently treat complex
                  cataracts.
                </p>
              </div>
            </div>
          </div>
          <div className="services-div-content-retina-services">
            <div className="services-div-content-retina-services-left">
              <div className="services-div-content-retina-services-left-heading">
                <h2>Retina Services</h2>
              </div>
              <div className="services-div-content-retina-services-left-content">
                <p>
                  We actively look for the presence of retinal problems in all
                  our patients through dilated fundas examinations. In this way,
                  we can pick up abnormal retinal conditions like diabetic
                  retinopathy at a relatively early stage and prevent visual
                  loss.We have advanced diagnostics like OCT, fundus cameras,
                  and state-of-the-art surgical facilities. (We offer medical
                  treatment, retinal laser treatment, and intravitreal
                  injections.) The retina is the fragile, light-sensitive tissue
                  at the back of the eye.Our retina specialists are highly
                  competent in the diagnosis and treatment of a wide range of
                  disorders connected to the retina. Our modern diagnostic
                  equipment and cutting-edge treatment choices enable us to
                  offer specialized care for a wide range of retinal disorders.
                </p>
              </div>
            </div>
            <div className="services-div-content-retina-services-right">
              <img src={services4} alt="services4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
