import React, { useEffect, useState } from "react";
import { Input, Textarea, ThemeIcon } from "@mantine/core";
import "./AddReview.css";
import { showNotification } from "@mantine/notifications";
import { Check, Edit, Trash, X } from "tabler-icons-react";
import LoginForm from "../../Components/LoginForm/LoginForm";
import { useNavigate } from "react-router-dom";
const AddReview = () => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem("userLogin"));

  const [fetchApi, setFetchApi] = useState();
  const [reviewTable, setReviewTable] = useState([]);
  const [currentReview, setCurrentReview] = useState([]);

  const [apiUnmount, setApiUnmount] = useState(false);

  const [addReview, setAddReview] = useState({
    reviewer: "",
    review: "",
  });
  const [updateReview, setUpdateReview] = useState({
    reviewer: "",
    review: "",
    id: "",
  });

  // useEffect(() => {
  //   if (!loggedIn) {
  //     navigate("/");
  //   }
  // }, [loggedIn]);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const sendReview = async () => {
    const { reviewer, review } = addReview;
    fetch(
      "https://sathish-eyecare-default-rtdb.asia-southeast1.firebasedatabase.app/review.json",
      {
        method: "POST",
        header: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reviewer: reviewer,
          review: review,
        }),
      }
    )
      .then((result) => {
        setFetchApi(1);
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="green">
              <Check color="green" />
            </ThemeIcon>
          ),
          message: <p style={{ color: "green" }}>Review Added Successfully</p>,
        });
        setAddReview({ reviewer: "", review: "" });
        setApiUnmount(!apiUnmount);
      })
      .catch((error) => {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: <p style={{ color: "red" }}>Error in Adding Review</p>,
        });
      });
  };

  const databaseUrl =
    "https://sathish-eyecare-default-rtdb.asia-southeast1.firebasedatabase.app";

  const fetchReviews = async () => {
    try {
      const response = await fetch(`${databaseUrl}/review.json`);

      if (!response.ok) {
        throw new Error("Failed to fetch reviews");
      }

      const data = await response.json();
      let values = Object.values(data);
      const key = Object.keys(data);
      let id = [];
      for (var i = 0; i < values?.length; i++) {
        id.push({ id: key[i] });
        // values[i] = [values[i], id[i]];
        values[i] = { ...values[i], ...id[i] };
      }

      setReviewTable(values || []);
    } catch (error) {
      console.error("Error fetching reviews:", error.message);
    }
  };

  const handileEdit = (id) => {
    const reviewToEdit = reviewTable.find((review) => review.id === id);
    setCurrentReview(reviewToEdit);
  };
  const handleUpdate = async () => {
    // Use the currentReview state to send the updated review
    const { id, reviewer, review } = updateReview;

    try {
      const response = await fetch(`${databaseUrl}/review/${id}.json`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reviewer: reviewer,
          review: review,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update review");
      }

      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="green">
            <Check color="green" />
          </ThemeIcon>
        ),
        message: <p style={{ color: "green" }}>Review Updated Successfully</p>,
      });

      // Clear the currentReview state and trigger a fetch
      setCurrentReview(null);
      setFetchApi(fetchApi + 1);
      setApiUnmount(!apiUnmount);
    } catch (error) {
      console.error("Error updating review:", error.message);
    }
  };

  // const handileDelete = async (id) => {
  //   const result = window.confirm("Are you Sure");
  //   if (result) {
  //     try {
  //       const response = await fetch(`${databaseUrl}/review/${id}.json`, {
  //         method: "DELETE",
  //       });

  //       if (!response.ok) {
  //         throw new Error("Failed to delete review");
  //       }

  //       showNotification({
  //         icon: (
  //           <ThemeIcon variant="light" radius="xl" size="xl" color="green">
  //             <Check color="green" />
  //           </ThemeIcon>
  //         ),
  //         message: (
  //           <p style={{ color: "green" }}>Review Deleted Successfully</p>
  //         ),
  //       });

  //       // Trigger a fetch after deletion
  //       setFetchApi(fetchApi + 1);
  //       setApiUnmount(!apiUnmount);
  //     } catch (error) {
  //       console.error("Error deleting review:", error.message);
  //     }
  //   }
  // };
  const handileDelete = async (id) => {
    const result = window.confirm("Are you Sure");
    if (result) {
      try {
        const response = await fetch(`${databaseUrl}/review/${id}.json`, {
          method: "DELETE",
        });

        if (!response.ok) {
          throw new Error("Failed to delete review");
        }

        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="green">
              <Check color="green" />
            </ThemeIcon>
          ),
          message: (
            <p style={{ color: "green" }}>Review Deleted Successfully</p>
          ),
        });

        // Update the reviewTable state after successful deletion
        setReviewTable((prevReviewTable) =>
          prevReviewTable.filter((review) => review.id !== id)
        );

        setFetchApi((prevFetchApi) => prevFetchApi + 1);
      } catch (error) {
        console.error("Error deleting review:", error.message);
      }
    }
  };

  useEffect(() => {
    if (currentReview) {
      setUpdateReview((previousData) => ({
        ...previousData,
        reviewer: currentReview.reviewer,
        review: currentReview.review,
        id: currentReview.id,
      }));
    }
  }, [currentReview, apiUnmount]);

  useEffect(() => {
    fetchReviews();
  }, [fetchApi, apiUnmount]);

  return (
    <div>
      <div className="add-review-div">
        {!loggedIn ? (
          <LoginForm onLogin={handleLogin} />
        ) : (
          <div className="add-review-div-container">
            <div className="add-review-div-container-heading">
              <h2>Add Review</h2>
            </div>
            <div className="add-review-div-container-content">
              <div className="add-review-div-container-content-form">
                <div className="add-review-div-container-content-form-individual">
                  <Input.Wrapper label="Reviewer Name">
                    <Input
                      onChange={
                        currentReview && currentReview.reviewer
                          ? (e) => {
                              setUpdateReview({
                                ...updateReview,
                                reviewer: e.target.value,
                              });
                            }
                          : (e) => {
                              setAddReview({
                                ...addReview,
                                reviewer: e.target.value,
                              });
                            }
                      }
                      value={
                        currentReview && currentReview.reviewer
                          ? updateReview.reviewer
                          : addReview.reviewer
                      }
                    />
                  </Input.Wrapper>
                </div>
                <div className="add-review-div-container-content-form-individual">
                  <Input.Wrapper label="Review">
                    <Textarea
                      autosize
                      minRows={5}
                      onChange={
                        currentReview && currentReview.review
                          ? (e) => {
                              setUpdateReview({
                                ...updateReview,
                                review: e.target.value,
                              });
                            }
                          : (e) => {
                              setAddReview({
                                ...addReview,
                                review: e.target.value,
                              });
                            }
                      }
                      value={
                        currentReview && currentReview.review
                          ? updateReview.review
                          : addReview.review
                      }
                    />
                  </Input.Wrapper>
                  {currentReview && currentReview.id ? (
                    <input
                      type="hidden"
                      onChange={
                        currentReview && currentReview.id
                          ? (e) => {
                              setUpdateReview({
                                ...updateReview,
                                id: e.target.value,
                              });
                            }
                          : ""
                      }
                      value={updateReview.id}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="add-review-div-container-content-form-btn">
                  <button
                    onClick={
                      currentReview && currentReview.id
                        ? handleUpdate
                        : sendReview
                    }
                  >
                    {currentReview && currentReview.id
                      ? "Update Review"
                      : "Add Review"}
                  </button>
                </div>
              </div>
              <div className="add-review-div-container-content-table">
                <table className="review-show">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Reviewer Name</th>
                      <th>Review</th>
                      <th>Option</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviewTable.length === 0 ? (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          No Reviews Available
                        </td>
                      </tr>
                    ) : (
                      reviewTable.map((reviewtable, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{reviewtable.reviewer}</td>
                            <td>{reviewtable.review}</td>
                            <td>
                              <Edit
                                className="review-button"
                                onClick={() => {
                                  handileEdit(reviewtable.id);
                                }}
                              />
                              <Trash
                                onClick={() => {
                                  handileDelete(reviewtable.id);
                                }}
                                className="review-button"
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddReview;
